/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import MaskedInput from "react-maskedinput"; 
import Back from "./components/back";

import Topo from './components/topo';
import Rodape from './components/rodape';

class CadastrarPaciente extends Component {
 constructor(props) {
    super(props);
      state = {
      nome:       '',
      cpf:        '',
      nascimento: '',
      celular:    '',
      email:      '',
      senha:      '',
      dados:      [],
      cor:        'primary', 
      dadosPaciente: [],
      alert: "Realize seu cadastro",
      existe: false,
  }
}

  componentDidMount(){
    const cpf = sessionStorage.getItem('@masterclinicapwa/cpf')

    axios.get(`https://mgabr.com/apiapp/demostracao/dadosPacienteCadastro.php?cpf=${cpf}`, { 
      params: {
        cpf
      }
    }
    )
    .then(res => {
      const dadosPaciente = res.data;       
      this.setState({ dadosPaciente });
      
      if (dadosPaciente.length > 0) {
        this.setState( {alert:'Encontramos os seguintes dados, altere ou complete seu cadastro'})
        this.setState({existe:true})
        this.setState({nome:dadosPaciente[0].nome})
        this.setState({cpf:dadosPaciente[0].cpf})
        this.setState({nascimento:dadosPaciente[0].nascimento})
        this.setState({celular:dadosPaciente[0].celular})
        this.setState({email:dadosPaciente[0].email})
      }
      });
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value 
    })
    if(this.state.cpf === '11111111111'){
      alert('ERRADO')
    }

  }

  cadastrar = (e) => {
       
    axios.get('https://mgabr.com/apiapp/demostracao/cadastrarpaciente.php', {
      params: {
        nome:       this.state.nome,
        cpf :       this.state.cpf,
        nascimento: this.state.nascimento,
        celular:    this.state.celular,
        email:      this.state.email,
        senha:      this.state.senha
      }
    }
    )
      .then(res => {
        const dados = res.data;
        this.setState({ dados });
    

        if (dados.length === 0) {
          this.setState({ botao: 'Ocorreu um erro' })
          this.setState({ cor: 'danger' })
        } else {
         alert("Paciente cadastrado com sucesso!")
          this.props.history.push('/loginPaciente')
        }     
      });
      e.preventDefault();  
    }

  render() {    
    return (
      <div>
            <style type="text/css">
            {`
                .container{                  
                  overflow-y: auto;
                  height: 100vh;
                }
                 
                 .form-group{
                  margin-bottom: 0px !important;
                } 
                .btn{margin-top:10px !important}    
                label{margin: 8px 0  1px 0 !important}      
  
               `}
          </style>
        <Topo />      

        <Container className="mt-4">

        <Row className="mt-2 mb-3">
            <Col><Back /></Col>
          
            <Alert variant="primary mt-4">{this.state.alert}</Alert>
            <Col />
          </Row>
          {this.state.existe ?
               (
                <Form onSubmit={this.cadastrar} >
                <Row>
                  <Col sm={6}>
                  
                  <Form.Group controlId="nome">
                  <Form.Label>Nome Completo *</Form.Label>
                  {this.state.dadosPaciente.map(paciente => <Form.Control key={paciente.id} placeholder="Nome Completo" name="nome" type="text" defaultValue={paciente.nome} onChange={this.handleChange} required ref={this.input} />)}
                  </Form.Group>
  
                  <Form.Group controlId="cpf">
                    <Form.Label>CPF *</Form.Label>
                    {this.state.dadosPaciente.map(paciente => <Form.Control key={paciente.id} name="cpf" type="text" value={paciente.cpf}  onChange={this.handleChange}  ref={this.input} required />)}
                  </Form.Group>
  
                  <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  {this.state.dadosPaciente.map(paciente => <Form.Control key={paciente.id} name="email" type="type" onChange={this.handleChange} value={paciente.email} />)}
                  </Form.Group>
  
                  </Col>
                  
                  <Col sm={6}>
                  <Form.Group controlId="celular">
                    <Form.Label>Celular *</Form.Label>
                    {this.state.dadosPaciente.map(paciente =><Form.Control  key={paciente.id} name="celular" type="text" onChange={this.handleChange}  value={paciente.celular} required/>)}
                  </Form.Group>
  
                   <Form.Group controlId="nascimento">
                    <Form.Label>Data de Nascimento *</Form.Label>
                    {this.state.dadosPaciente.map(paciente =><Form.Control  key={paciente.id} name="nascimento" type="text" onChange={this.handleChange} value={paciente.nascimento} required/>)}
                  </Form.Group>
  
                  <Form.Group controlId="senha">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control name="senha" type="text" onChange={this.handleChange} required />
                  </Form.Group>
                  </Col>
                  </Row>              
                 
                 
                  <Button variant="success" className="botaoForm" size="lg" block type="submit">Cadastrar ou Alterar</Button>
                </Form>
              )
              :
              ( <Form onSubmit={this.cadastrar} >
                <Row>
                  <Col sm={6}>
                  
                  <Form.Group controlId="nome">
                  <Form.Label>Nome Completo *</Form.Label>
                  <Form.Control  name="nome" type="text"  onChange={this.handleChange}  required />
                  </Form.Group>
  
                  <Form.Group controlId="cpf">
                    <Form.Label>CPF *</Form.Label>
                   <Form.Control as={MaskedInput} mask="111.111.111-11" name="cpf" type="text" onChange={this.handleChange}  required />
                  </Form.Group>
  
                  <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control name="email" type="email" onChange={this.handleChange}  />
                  </Form.Group>
  
  
                  </Col>
                  
                  <Col sm={6}>
                  <Form.Group controlId="celular">
                    <Form.Label>Celular *</Form.Label>
                   <Form.Control  as={MaskedInput} mask="(11)11111-1111" name="celular" type="text" onChange={this.handleChange} required/>
                  </Form.Group>
  
                   <Form.Group controlId="nascimento">
                    <Form.Label>Data de Nascimento *</Form.Label>
                   <Form.Control as={MaskedInput} mask="11/11/1111" name="nascimento" type="text" onChange={this.handleChange}  required/>
                  </Form.Group>
  
                  <Form.Group controlId="senha">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control name="senha" type="text" onChange={this.handleChange} />
                  </Form.Group>
                  </Col>
                  </Row>              
                 
                 
                  <Button variant="success" className="botaoForm" size="lg" block type="submit">Cadastrar ou Alterar</Button>
                </Form>
                )
              }
           
          
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default CadastrarPaciente;
