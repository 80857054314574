/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import { Container, ListGroup, Button, Spinner, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Back from "./components/back";
import Topo from './components/topo';
import Rodape from './components/rodape';


class Profissionais extends Component {

      state = {
      profissionais: [],
      loading: false,
    }
  

componentDidMount() {
  const base = process.env.REACT_APP_BASE;
  const especialidadeEscolhida = sessionStorage.getItem('@masterclinicapwa/especialidade')
  axios.get(`https://mgabr.com/apiagendamento/profissionais.php?banco=${base}&especialidade=${especialidadeEscolhida}`)      
    .then(res => {
      const profissionais = res.data;
      console.log(profissionais);
      this.setState({ profissionais });
      this.setState({ loading: true });
    });

 }

  handleClick = (e) => { 
    sessionStorage.setItem('@masterclinicapwa/profissional', e);
    sessionStorage.setItem('@masterclinicapwa/voltar', '/profissionais')
    this.props.history.push('/calendario')
    
  }

  render() {
    return (
      <div>
         <style type="text/css">
            {`
              #gridProfissionais{
               max-height: 75vh;
               overflow-y: auto;
              }  
            `}
          </style>
        <Topo />
        <Container>
        <Row className="mt-2">
          <Col><Back /></Col>
            <Col className="text-center titulo">Profissional</Col>    
            <Col />        
        </Row>
    
          {this.state.loading ? (<ListGroup id="gridProfissionais">
            { this.state.profissionais.map(profissional => <ListGroup.Item key={profissional.cod_perfil}><Button className="buttonEspecialidade"  value={profissional.codigo} variant="disponivel" onClick={e=>this.handleClick(e.target.value)} >{profissional.prof}</Button></ListGroup.Item>)}
          </ListGroup>) : (<Spinner animation="border" className="loading" variant="success">
            <span className="sr-only">Aguarde...</span>
          </Spinner>)}


        </Container>
        <Rodape />
      </div>

    )
  }
}

export default Profissionais;
