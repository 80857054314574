import React, { Component } from 'react';
import { Container, ListGroup, Row, Button, Col } from 'react-bootstrap';
import Calendar from 'react-calendar'
import axios from 'axios';
import Back from "./components/back";
import Topo from './components/topo';
import Rodape from './components/rodape';

const qs = require('qs');


class Calendario extends Component {
   constructor(props) {
    super(props);
      this.state = {
        date: new Date(),
        agendamentos: [], 
        disabledDates: [],
  }
}

componentDidMount() {
  const base = process.env.REACT_APP_BASE;
/* Datas disponiveis do profissional */
// eslint-disable-next-line no-alert
alert("Selecione o dia (Marcados em Verde) e depois o horário. ")
    const disabledDates = [];
    const profissionalEscolhido = sessionStorage.getItem('@masterclinicapwa/profissional')

    axios.get(`https://mgabr.com/apiagendamento/calendariodatas.php?banco=${base}&profissional=${profissionalEscolhido}`)
      .then(res => {        
       /* disabledDates.push(new Date(res.data)); */ 
       const dia = res.data.map(
         // eslint-disable-next-line no-shadow
         (dia) => disabledDates.push(new Date(dia.dia))
         );    
        disabledDates.push(new Date(dia)); 
        this.setState({disabledDates})
      })
   
  }
  
  handleClick = (e) => { 

    sessionStorage.setItem('@masterclinicapwa/hora', e);
    sessionStorage.setItem('@masterclinicapwa/voltar', '/calendario')
    this.props.history.push('/convenio')

    /* AQUI CHAMA A API PARA SETAR A TRAVA */

    const data = qs.stringify({
      'idvaga': e           
       });
       
       const config = {
         method: 'post',
         url: 'https://mgabr.com/apiapp/endoserv/travaVaga.php',
         headers: { 
           'Content-Type': 'application/x-www-form-urlencoded'
         },
         data
       };
       
       axios(config)
       .then(function (response) {
         console.log(JSON.stringify(response.data));
       })
       .catch(function (error) {
         console.log(error);
       });

  }
  



  onChange = (e) => {
    const base = process.env.REACT_APP_BASE;
    const profissionalEscolhido = sessionStorage.getItem('@masterclinicapwa/profissional')
 
    const dataescolhida = e.toLocaleDateString("fr-CA")
    const dataMostra = e.toLocaleDateString("pt-BR")  


        axios.get(`https://mgabr.com/apiagendamento/calendariohorarios.php?banco=${base}&profissional=${profissionalEscolhido}&data=${dataescolhida}`)
      .then(res => {
        const agendamentos = res.data;
        sessionStorage.setItem('@masterclinicapwa/data', dataescolhida);
        sessionStorage.setItem('@masterclinicapwa/dataMostra', dataMostra);
        this.setState({ agendamentos:[]})
        this.setState({ agendamentos });

      })
  }

  render() {
      return ( 
      <div>
        <style type="text/css">
        {`#listaHorarios{
          max-height: 50vh;
          overflow-y: auto;
          } 
          .react-calendar__tile--vagas {
            background: #00cc8b;
            color: white;
          }  
       `}
      </style>
        <Topo />
        <Container>
          <Row className="mt-2">
            <Col><Back /></Col>
            <Col className="text-center titulo">Horário</Col>    
            <Col/>        
          </Row>        
        
            <Row>    
            <Col md="auto">
            <Calendar className="" onChange={this.onChange}  value={this.state.date} tileClassName={({date}) => this.state.disabledDates.some(disabledDate =>date.getYear() === disabledDate.getYear() && date.getMonth() === disabledDate.getMonth() && date.getDate() === disabledDate.getDate() )? 'react-calendar__tile--vagas' : null} />
            </Col>
     
          <Col md="auto">
          <div className="text-center text-info">{sessionStorage.getItem('@masterclinicapwa/dataMostra')}</div>
          <div id="listaHorarios">
            
          
            <ListGroup horizontal id="gridHorarios">
            {this.state.agendamentos.map(agendamento => <ListGroup.Item key={agendamento.id}><Button constiant="disponivel" value={agendamento.id} onClick={e=>this.handleClick(e.target.value)} >{`${agendamento.hora.slice(0,2)}:${agendamento.hora.slice(2,4)}`}</Button></ListGroup.Item>)}
            </ListGroup>     
          </div>
          </Col>
          </Row>
        </Container>
        <Rodape />

      </div>
    );
  };
};

export default Calendario;
