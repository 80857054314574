/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { Navbar, Image, Nav } from 'react-bootstrap';
import { HiOutlineUserCircle, HiLogout } from "react-icons/hi";
import { Link } from 'react-router-dom';


class Topo extends Component {

  handleClick = () => {
    sessionStorage.clear();
  }

  

  render() {
    const logado = sessionStorage.getItem('@masterclinicapwa/login')
    const loginMedico = process.env.REACT_APP_LOGIN_MEDICO;
    const usaLaudo = process.env.REACT_APP_USA_LAUDO;
    const logo = process.env.REACT_APP_LOGO;
    return (
      <div>
       
         <Navbar className="topo shadow">
       
        <Navbar.Brand href="/"> <Image  src={logo} className="imageTopo center" /></Navbar.Brand>
        {logado === 'Profissional' &&
          <Nav className="mr-auto somenteDesk">         
          <Nav.Link><Link to='/consultas' className="nav__link_topo">Minha Agenda</Link></Nav.Link>           
          </Nav>
          }
         {logado === 'Profissional' && usaLaudo === '1' &&
          <Nav className="mr-auto somenteDesk"> 
          <Nav.Link><Link to='/laudosProfissional' className="nav__link_topo" >Laudos</Link></Nav.Link>     
          </Nav>
          }

        {logado !== 'Profissional' &&
          <Nav className="mr-auto somenteDesk">         
          <Nav.Link><Link to='/loginPaciente' className="nav__link_topo">Agendamentos</Link></Nav.Link>          
          </Nav>
          }
        
        {logado !== 'Profissional' && usaLaudo === '1' &&
          <Nav className="mr-auto somenteDesk">  
          <Nav.Link><Link to='/laudos' className="nav__link_topo" >Laudos</Link></Nav.Link>      
          </Nav>
          }


       
          <Navbar.Collapse className="justify-content-end">
          <Nav.Link>

          {logado === 'Profissional' && loginMedico === '1' &&
          <Link to ='/' onClick={()=>this.handleClick()}><HiLogout color="lightsteelblue" size={30} /></Link>
          }
          {logado !== 'Profissional' && loginMedico === '1' &&
           <Link to ='/login'><HiOutlineUserCircle color="lightsteelblue" size={30}  /></Link>  
          }        
          </Nav.Link>
          </Navbar.Collapse>
          
        </Navbar>


      </div>
    );
  }
}
export default Topo;