import React, { Component } from 'react';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import Topo from './components/topo';
import Rodape from './components/rodape';

class prontuariosPaciente extends Component {
   constructor(props) {
    super(props);
      state = {
      pacientes: [],
      paciente: '',
      selectedOption: null,
      options:[]
    }
}


  componentDidMount() {
    
    const crmEscolhido = sessionStorage.getItem('@masterclinicapwa/crm')

    axios.get(`https://mgabr.com/apiapp/demostracao/apipacientes.php?crm=${crmEscolhido}`)
      .then(res => {
        const options = res.data;
        this.setState({ options });
      });
  }


  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => sessionStorage.setItem('@masterclinicapwa/paciente', this.state.selectedOption.value)
    );  
    
  };


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.history.push('/mostraProntuario')
  }

  render() {

    const { selectedOption } = this.state;


    return (
      <div>
        <Topo />
        <Container>
          <Row className="mt-2">        
            <Col className="text-center titulo">Prontuarios</Col>           
          </Row>
            
          <Form onSubmit={this.handleSubmit} >
            <Form.Group controlId="paciente">
            <Form.Label className="mt-3">Nome ou código do paciente</Form.Label>
            <Select value={selectedOption}  onChange={this.handleChange}  options={this.state.options} />
            </Form.Group>
            <Button variant="success" size="lg" block type="submit">Consultar</Button>
          </Form>

        </Container>
        <Rodape />
      </div>
    );
  };
};

export default prontuariosPaciente;
