/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable global-require */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Navbar, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { HiHome,HiOutlineCalendar,HiCollection,HiOutlineClipboardList } from "react-icons/hi";

class Rodape extends Component {
    render() {
        const logado = sessionStorage.getItem('@masterclinicapwa/login')
        return (
            <div>   
            <style type="text/css">
            {`.mgaBottom {
               width: 20%;
               max-width: 30px;
            }
            .rodape {padding: 0px !important}`}
            </style>                 
                <Navbar fixed="bottom" className="justify-content-center rodape shadow">                  
                        <Row>  
                                                   
                            {logado === 'Profissional' &&
                                <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">                           
                                <Link to='/dashboard' class="nav__link">
                                   <HiHome color="lightsteelblue"  size={30} />                  
                                   <span className="nav__text" >Início</span>
                                </Link>
                                </Col>   
                            }
                             {logado !== 'Profissional' &&
                                <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">                           
                                <Link to='/' class="nav__link">
                                   <HiHome color="lightsteelblue"  size={30} />                  
                                   <span className="nav__text" >Início</span>
                                </Link>
                                </Col>   
                            }
                              {logado === 'Profissional' &&
                                <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">                           
                                     <Link to='/consultas' class="nav__link">
                                        <HiOutlineCalendar color="lightsteelblue"  size={30} />                  
                                        <span className="nav__text" >Agenda</span>
                                    </Link>
                                </Col>
                            }
                             {logado !== 'Profissional' &&
                                <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">                           
                                     <Link to='/loginPaciente' class="nav__link">
                                        <HiOutlineCalendar color="lightsteelblue"  size={30} />                  
                                        <span className="nav__text" >Agenda</span>
                                    </Link>
                                </Col>
                            }
                            
                            {logado === 'Profissional' &&
                            <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">
                                <Link to='/laudosProfissional' class="nav__link"><HiCollection color="lightsteelblue" size={30} />
                                <span className="nav__text">Laudos</span>
                                </Link>
                            </Col>
                            }

                            {logado !== 'Profissional' &&
                            <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">
                                <Link to='/laudos' class="nav__link"><HiCollection color="lightsteelblue" size={30} />
                                <span className="nav__text">Laudos</span>
                                </Link>
                            </Col>
                            }

                            {logado === 'Profissional' &&
                                  <Col className="ml-3 mr-3 mt-1 mb-1 somenteMobile">
                                    <Link to='/prontuariosPaciente' class="nav__link"><HiOutlineClipboardList color="lightsteelblue" size={30} />
                                     <span className="nav__text">Prontuários</span>
                                    </Link>
                                 </Col>
                            }                            
                          
                            <Col className="ml-3 mr-3 mt-1 mb-1  somenteDesk">
                              
                                <span><Image src={'logo512.png'} className="mgaBottom" />© 2021 Master Med</span>
                         
                            </Col>
                        </Row>
                </Navbar > 
              
            </div>
        );
    }
}

export default Rodape;