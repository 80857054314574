import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';


class Dashboard extends Component {
   constructor(props) {
    super(props);
    state = { }
  }

  render() {

    return (
      <div>
        
          <style type="text/css">
            {`
                .btn{margin-top:10px !important}
            `}
          </style>
        <Topo />
        <Container>
        <Row className="mt-2">    
        <Col className="text-center titulo">Olá {sessionStorage.getItem('@masterclinicapwa/profissional')}</Col>
        </Row>
          <Link to='/consultas'>
            <Button variant="outline-success" size="lg" block>
              Minha Agenda
          </Button>
          </Link>
          <Link to='/laudosProfissional'>
            <Button variant="outline-success" size="lg" block>
              Consultar Laudos
          </Button>
          </Link>
          <Link to='/prontuariosPaciente'>
            <Button variant="outline-success" size="lg" block>
              Consultar Prontuários
          </Button>
          </Link>
        </Container>
        <Rodape />
      </div>

    )
  }
}

export default Dashboard;
