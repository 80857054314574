/* eslint-disable react/state-in-constructor */
/* eslint-disable global-require */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Container, Button, Form, Row ,Col, Image } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';




class NovaSenha extends Component {

      state = {
      email: '',
      senha: '',
      dados: [],
      botao: 'Validar',
      cor: 'primary',
      alert: false,
      link: '',
      button: false,
      uuid: '',     
  }


  componentDidMount(){
    const url  = window.location.href
    const codPac= url.slice(url.indexOf("/") + 32);
    this.setState({uuid:codPac})
    console.log(codPac)

    axios.get('https://mgabr.com/apiapp/demostracao/verificaid.php', {
      params: {        
        uuid: this.state.uuid,
      }
    })
      .then(res => {      
        const dados = res.data;
        this.setState({ dados });
        this.setState({email: dados[0].email});
        if (dados.length === 0) {
          this.setState({ botao: 'Esse link expirou, tente novamente.' })
          this.setState({ cor: 'danger' })
          this.setState({ button: true})
        } else {
          this.setState({ botao: 'Alterar' })
          this.setState({ cor: 'success' })
          this.setState({ alert: true })             
        }
      });
    }
  
  
  

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

  }

  handleSubmit = (e) => {

    axios.get('https://mgabr.com/apiapp/demostracao/alterasenha.php', {
      params: {
        email: this.state.email,
        senha: this.state.senha
      }
    })
      .then(res => {
        const dados = res.data;
        this.setState({ dados });
        if (dados.length === 0) {
          this.setState({ botao: 'Não foi possível alterar, atualize seu cadastro' })
          this.setState({ cor: 'danger' })
          this.setState({ button: true})
        } else {
          this.setState({ botao: 'Login' })
          this.setState({ cor: 'success' })
          this.setState({ alert: true })

          this.props.history.push('/loginPaciente')
         
        }

      });


    e.preventDefault();
  }



  cadastrar = (e) => {
    this.props.history.push('/loginPaciente')  
    e.preventDefault();
  }



  render() {
    const entrar = this.state.botao
    return (

      <div>
        <Topo />
        <Container className="mt-4">
        <Row>    
        <Col className="text-center titulo mt-2">Esqueci minha senha</Col>
        </Row>
        <Row className="mt-2 bg-white justify-content-md-center">           
          <Col className="somenteDesk p-4">
          <Image src={require('./appInicio.jpg')}className="imagem" />
          </Col>
          <Col>        
         
            <Form onSubmit={this.handleSubmit} >
                <Form.Group controlId="crm">
                  <Form.Label>Nova Senha</Form.Label>
                  <Form.Control name="senha" type="password" onChange={this.handleChange} />
                </Form.Group>               
                <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
              </Form>
              {this.state.button ?(
                 <Link to='/cadastrarPaciente'><Button variant="outline-danger" size="lg" block type="submit">Cadastrar</Button></Link>
              ):('')}
             
              </Col>
              </Row>
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default NovaSenha;
