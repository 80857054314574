/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Alert ,Col, Image } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid'
import Topo from './components/topo';
import Rodape from './components/rodape';




class RecuperarSenha extends Component {
 constructor(props) {
    super(props);
  state = {
    email: '',
    senha: '',
    dados: [],
    botao: 'Validar',
    cor: 'primary',
    alert: false,
    link: '',
    button: false,
    uuid: uuid()
  }
}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

  }

  handleSubmit = (e) => {
    axios.get('https://mgabr.com/apiapp/demostracao/recuperarsenha.php', {
      params: {
        email: this.state.email,
        id: this.state.id
      }
    })
      .then(res => {
        const dados = res.data;
        this.setState({ dados });
        if (dados.length === 0) {
          this.setState({ botao: 'Você não tem acesso' })
          this.setState({ cor: 'danger' })
          this.setState({ button: true})
        } else {
          this.setState({ botao: 'Login' })
          this.setState({ cor: 'success' })
          this.setState({ alert: true })

          axios.get('https://tinyurl.com/y5dhnb2t/agendamento/recuperarsenha', {
            params: {
              email: this.state.email,
              uuid: this.state.uuid
            }
          })
          .then(res=>{
            const resp = res.data;
            console.log(resp);
          })        
        }

      });

    this.state.dados.map((post, index) => {    
      this.props.history.push('/loginPaciente')
    })
    e.preventDefault();
  }

  cadastrar = (e) => {
    this.props.history.push('/loginPaciente')  
    e.preventDefault();
  }



  render() {
    const entrar = this.state.botao
    return (

      <div>
        <Topo />
        <Container className="mt-4">
        <Row>    
        <Col className="text-center titulo mt-2">Esqueci minha senha</Col>
        </Row>
        <Row className="mt-2 bg-white justify-content-md-center">           
          <Col className="somenteDesk p-4">
          <Image src={require('./appInicio.jpg')}className="imagem" />
          </Col>
          <Col>        
          {this.state.alert ?(<Alert variant='success'>Foi enviado um link em seu email</Alert>):('')}
            <Form onSubmit={this.handleSubmit} >
                <Form.Group controlId="crm">
                  <Form.Label>Email cadastrado</Form.Label>
                  <Form.Control name="email" type="email" onChange={this.handleChange} />
                </Form.Group>               
                <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
              </Form>
              {this.state.button ?(
                 <Link to='/cadastrarPaciente'><Button variant="outline-danger" size="lg" block type="submit">Cadastrar</Button></Link>
              ):('')}
             
              </Col>
              </Row>
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default RecuperarSenha;
