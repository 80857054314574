/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Col, Table} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import Topo from './components/topo';
import Rodape from './components/rodape';

class Laudos extends Component {

      state = {
      profissionais: [],
      loading: false,
      show: false,
      mensagem: 'Preencha os dados'
  }

  constructor(props) {
  super(props);

    this.state = { protocolo: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.volta = this.volta.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  volta(){
    this.setState({ loading: false });
 }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // eslint-disable-next-line global-require
    const qs = require('qs');
    const data = qs.stringify({});
    const paciente = this.state.protocolo
    const config = {
      method: 'get',
      url: `http://144.217.100.77/laudos/busca.php?paciente=${paciente}`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      data
    };
    axios(config)
      .then(response => {
        const protocolos = response.data;
        this.setState({ protocolos });
        this.setState({ loading: true });

      })
      .catch(function () {

      
    
      });

  }

  render() {

    let listaProtocolo;
    if(this.state.protocolos){
      listaProtocolo = this.state.protocolos.map(protocolo =><tr><td>{protocolo.dt_exame}</td><td key={protocolo.id}><a href={protocolo.arq} target="_blank" rel="noopener noreferrer" ><small>{protocolo.descricao}</small></a></td></tr>)
    }else{
      listaProtocolo = <tr><td>Nenhum item com esse CPF</td></tr>
    }
    return (
      <div>
        <Topo/>
        <Container>
        <Row className="mt-2">        
            <Col className="text-center titulo">Laudos</Col>           
          </Row>
          <style type="text/css">
            {`
                 .font08{font-size:0.8rem}
                 .btn-disponivel {
                  background-color: #00cc8b;    
                  color:white;      
                  width:100%;       
                  height:70px;
                 }
                 .btn-ocupado {
                  background-color: #ff4d4d;
                  color:white;                 
                 }
                 #gridLaudos{
                  max-height: 70vh;
                  overflow-y: auto;
                 }
                 
               `}
          </style>

          {this.state.loading ? (<Link  onClick={this.volta}> <ArrowLeftCircleFill  color="lightsteelblue" size={30}/></Link>) : (" ")}
          {this.state.loading ? (
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Data</th>
              <th>Nome do Exame</th>           
            </tr>
          </thead>
          <tbody>            
            { listaProtocolo }
          </tbody>
          </Table>) 
          :
          <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="nome">
                <Form.Label>CPF</Form.Label>
                <Form.Control name="protocolo" type="text" onChange={this.handleChange} />
              </Form.Group>

              <Button constiant="success" size="lg" block type="submit">
                Consultar
            </Button>
            </Form>}

        </Container>
        <Rodape />

      </div>
    );
  };
};

export default Laudos;
