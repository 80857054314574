/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Container, Button, Row, Alert, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';

const qs = require('qs');


class Sucesso extends Component {

      state = {
        dadosVaga: [],
        idVaga: '',
      }




  render() {
    return (
      <div>
        <style>
          {`
          .imgConfirmado{
            width:80%;
            max-width:400px;
          }
          `}
        </style>
        <Topo />
        <Container className="mt-4">
        <Alert variant="success">Sua solicitação foi enviada com Sucesso, você receberá uma mensagem em seu Whatsapp</Alert>

         
          <Link to='/'>
            <Button variant="success" size="lg" block>
              Voltar
          </Button>
          </Link>
         
        </Container>
        <Rodape />
      </div>

    )
  }
}

export default Sucesso;
