/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
/* eslint-disable func-names */
/* eslint-disable no-return-assign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Col, Table} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { HiOutlineArrowLeft } from "react-icons/hi";
import Select from 'react-select';
import Topo from './components/topo';
import Rodape from './components/rodape';

class laudosProfissional extends Component {

  constructor(props) {
    super(props);
    this.state = { protocolo: '', senha: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.volta = this.volta.bind(this);
     state = {
      profissionais: [],
      loading: false,
      show: false,
      mensagem: '',
      selectedOption: null,
      options:[]
  }
}

  componentDidMount(){

    const crmEscolhido = sessionStorage.getItem('@masterclinicapwa/crm')
    
    axios.get(`https://mgabr.com/apiapp/endoserv/apipacientesLaudos.php?crm=${crmEscolhido}`)
      .then(res => {
        const options = res.data;
        this.setState({ options });
      });
  }

  volta(){
     this.setState({ loading: false });
  }

  
  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => this.state.protocolo = this.state.selectedOption.value
    );
    
  };


  handleSubmit(event) {
    event.preventDefault();


    const qs = require('qs');
    const data = qs.stringify({});
    const paciente = this.state.selectedOption.value
    const config = {
      method: 'get',
      url: `https://mgabr.com/apiapp/demostracao/apilaudosprof.php?paciente=${paciente}`,
       data
    };
    axios(config)
      .then(response => {
        const protocolos = response.data;   
        this.setState({ protocolos });
        this.setState({ loading: true });

      })
      .catch(function () {

      });

  }




  

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
           <style type="text/css">
            {`
                 .font08{font-size:0.8rem}
                 .btn-disponivel {
                  background-color: #00cc8b;    
                  color:white;      
                  width:100%;       
                  height:70px;
                 }
                 .btn-ocupado {
                  background-color: #ff4d4d;
                  color:white;                 
                 }
                 #gridLaudos{
                  max-height: 70vh;
                  overflow-y: auto;
                 }
                 
               `}
          </style>

        <Topo />
        <Container className="mt-2">
        <Row className="mt-2">        
            <Col className="text-center titulo">Laudos</Col>           
          </Row>      

 

          {this.state.loading ? (<Link  onClick={this.volta}> <HiOutlineArrowLeft  color="lightsteelblue" size={30}/></Link>) : (" ")}
          {this.state.loading ? (
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Data</th>
              <th>Nome do Exame</th>           
            </tr>
          </thead>
          <tbody>
            { this.state.protocolos.map(protocolo =><tr><td>{protocolo.dt_exame}</td><td key={protocolo.id}><a href={protocolo.arq} target="_blank" rel="noopener noreferrer" ><small>{protocolo.descricao}</small></a></td></tr>)}
          </tbody>
          </Table>)  : <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="nome">
                <Form.Label className="mt-3">Nome ou código do paciente</Form.Label>
                <Select value={selectedOption}  onChange={this.handleChange}  options={this.state.options} />
            
              </Form.Group>
              <Button variant="success" size="lg" block type="submit">
                Consultar
            </Button>
            </Form>}

        </Container>
        <Rodape />

      </div>
    );
  };
};

export default laudosProfissional;
