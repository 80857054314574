/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import { Container, ListGroup} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { ArrowLeftCircleFill} from 'react-bootstrap-icons';
import Topo from './components/topo';
import Rodape from './components/rodape';

class mostraAgendasProfissional extends Component {

      state = {
      horarios: [],
  }


  componentDidMount() {
    axios.get(
      'https://mgabr.com/apiapp/demostracao/agendasProfissional.php', {
      params: {
        profissional: '83545'
      }
    }
    )
      .then(res => {
        const horarios = res.data;
        this.setState({ horarios });
      });
  }

  render() {
    return (
      <div>
        <Topo />
        <Container className="mt-2">

          <style type="text/css">
            {`
                 .btn-disponivel {
                  background-color: #00cc8b;    
                  color:white;      
                  width:100%;       
                  height:70px;
                 }
                 .btn-ocupado {
                  background-color: #ff4d4d;
                  color:white;                 
                 }
                 #gridLaudos{
                  max-height: 70vh;
                  overflow-y: auto;
                 }
                 .list-group-item{border-bottom:solid #ccc 1px !important}
                 
               `}
          </style>
                 <Link to='/consultas'>
                                        <ArrowLeftCircleFill color="lightsteelblue"  size={30} />    </Link>              
                                         Vagas do dia {sessionStorage.getItem('@masterclinicapwa/dataMostraProfissional')}
                                    
          <ListGroup>
          { this.state.horarios.map(horario =>
          
            <ListGroup.Item>{horario.hora} - {horario.nome}</ListGroup.Item>)}
           
          </ListGroup>

        </Container>
        <Rodape />
      </div>
    );
  };
};

export default mostraAgendasProfissional;
