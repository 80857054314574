import React, { Component } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Topo from './components/topo';
import Rodape from './components/rodape';


class mostraProntuarioExterno extends Component {
    constructor(props) {
    super(props);
      state = {
        dadosProntuario: [{id: "201000117", data: "Verifique o código e tente novamente", hora: "", historico: "", nome: "NENHUM PACIENTE COM ESSE CÓDIGO"}],
        dadosPaciente:[]
      }
}


  componentDidMount() {
  const url  = window.location.href
  const codPac= url.slice(url.indexOf("/") + 46);

  console.log(codPac);

    axios.get('https://mgabr.com/apiapp/demostracao/buscaProntuarios.php', { 
      params: {
        codPac
      }
    }
    )
      .then(res => {
        const dadosProntuario = res.data;
        if(dadosProntuario.length > 0){
          this.setState({ dadosProntuario });
        }
      });

      /* GAMBI ARRUMAR DEPOIS */
      axios.get('https://mgabr.com/apiapp/demostracao/buscaPaciente.php', { 
      params: {
        codPac
      }
    }
    )
      .then(res => {
        const dadosPaciente = res.data;  
        this.setState({ dadosPaciente });
      });
  }

  render() {

    
    return (

      
      <div>
         <style type="text/css">
            {`
                 
                 .form-group{
                  margin-bottom: 0px !important;
                } 
                .btn{margin-top:10px !important}
                .form-control{
                  height: 2em !important
                }
              
                label{margin-bottom: 0px !important}
                .font08{font-size:0.8rem}
                .font09{font-size:0.9rem}

                #gridProntuarios{
                  max-height: 70vh;
                  overflow-y: auto;
                 }
  
               `}
          </style>
        <Topo />
        <Container className="mt-2">
          <Row>
            <Col>
                   
            {this.state.dadosPaciente.map(paciente =><span className="mt-2 mb-0 titulo text-center">{paciente.nome}</span>)}
            </Col>
            </Row>
                

          
          <div className="">
            <VerticalTimeline id="gridProntuarios" className="mt-4">
                {this.state.dadosProntuario.map(prontuario =>
                <VerticalTimelineElement key={prontuario.id}
                  className="vertical-timeline-element--work"
                >
                  <h5 className="vertical-timeline-element-title text-info">{prontuario.data}  {prontuario.hora}</h5>
                  <p>
                      {prontuario.historico}
                      
                  </p>
                  <div><br />
                    {/*  <h5>Anexos</h5>
                <Journal color="lightsteelblue" size={30} /> <span class="nav__text">Anexo 1</span>
                <Journal color="lightsteelblue" size={30} /> <span class="nav__text">Anexo 1</span>
              */}
                  </div>
                </VerticalTimelineElement>
              )}
            </VerticalTimeline>

          </div>


        </Container>
        <Rodape />
      </div>

    )
  }
}

export default mostraProntuarioExterno;
