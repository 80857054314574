/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/state-in-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Container, ListGroup, Row,Col} from 'react-bootstrap';
import Calendar from 'react-calendar'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';

class Consultas extends Component {

    state = {
    date: new Date(),
    agendamentos: [], 
    disabledDates: [],
    // loading: true,
    // codpac: ''
  }


  handleClick = (e) => { 

    sessionStorage.setItem('@masterclinicapwa/hora', e);
    sessionStorage.setItem('@masterclinicapwa/voltar', '/calendario')
    this.props.history.push('/convenio')
  }

  // buttonProntuario() {
  //   this.setState({ paciente: '100496' });
  //   sessionStorage.setItem('@masterclinicapwa/paciente','100496')
   
  // }
  
  

    onChange = (e) => {
 
    const dataescolhida = e.toLocaleDateString("fr-CA")
    const dataMostra = e.toLocaleDateString("pt-BR")
    const base = process.env.REACT_APP_BASE;
    const prof = sessionStorage.getItem('@masterclinicapwa/profissional')
    

      axios.get(`https://mgabr.com/apiagendamento/agendasProfissional.php?banco=${base}&data=${dataescolhida}&prof=${prof}`)
      .then(res => {
        const agendamentos = res.data;
        sessionStorage.setItem('@masterclinicapwa/data', dataescolhida);
        sessionStorage.setItem('@masterclinicapwa/dataMostra', dataMostra);

        this.setState({ agendamentos });        


      })
  }


  render() {
   
      return (
      <div>
        <Topo />
        <Container>

        <Row className="mt-2">    
            <Col className="text-center titulo">Agenda</Col>             
          </Row>
        <style type="text/css">
              {`
                 .btn-disponivel {
                  background-color: #00cc8b;    
                  color:white;             
                 }
                 .btn-ocupado {
                  background-color: #ff4d4d;
                  color:white;                 
                 }
                 .listaHorarios{
                  max-height: 30vh;
                  overflow-y: auto;
           
                 }
                 #listaAgendas{  display: grid !important;
                  grid-template-columns: repeat(1, 4fr);
                  grid-gap: 0.0em;}
                 .topoLista{margin-top: 80px !important}
                 .react-calendar__tile {padding:0.50em 0.5em !important}
                 .react-calendar__navigation {margin-bottom: -5px !important}
                 .list-group-item{border-bottom:solid #ccc 1px !important; font-size:12px !important}
                 .hora{font-size:20px; color:#00cc8b; font-weight:bold}
                 .hora span{font-size:12px; margin-left:5px; color:#4d4d4d}
                 



                 
               `}
            </style>
        
          
          <Row>    
          <Col >
            <Calendar onChange={this.onChange}  value={this.state.date} tileClassName={({date}) => this.state.disabledDates.some(disabledDate =>date.getYear() === disabledDate.getYear() && date.getMonth() === disabledDate.getMonth() && date.getDate() === disabledDate.getDate() )? 'react-calendar__tile--vagas' : null} />
          </Col>
          <Col>
          <div className="text-center text-info">{sessionStorage.getItem('@masterclinicapwa/dataMostra')}</div>
          <div id="listaHorarios" className="listaHorarios">          
          <ListGroup>                  
          
            { this.state.agendamentos.map(agendamento =>            
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link  onClick={()=> window.open(`/mostraProntuarioExterno/${agendamento.codpac}, "_blank"`)} >
              <ListGroup.Item>
                <div className='hora'>{`${agendamento.hora.slice(0,2)}:${agendamento.hora.slice(2,4)}`}<span className='nomePaci'>{agendamento.nome}</span></div> 
                </ListGroup.Item>
              </Link>)}
            </ListGroup>    
          </div>
          </Col>
          </Row>

        </Container>
        <Rodape />

      </div>
    );
  };
}
 export default Consultas;
