/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/state-in-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Col, Image} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';


class Login extends Component {
  
    state = {
      crm: '',
      senha: '',
      dados:[],
      botao:'Validar',
      cor:'primary'
  
}

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
    
  }

  handleSubmit =(e) => {

    const base = process.env.REACT_APP_BASE;

      
    axios.get('https://mgabr.com/apiagendamento/valida.php',{
      params:{
        crm:this.state.crm,
        senha:this.state.senha,
        banco:base    
      }}
      )   
      .then(res => {
        const dados = res.data;          
          this.setState({ dados });    
          if(dados.length === 0){
            this.setState({botao:'Inválido, Tente novamente'})   
            this.setState({cor:'danger'})
          }else{
            this.setState({botao:'Entrar'}) 
            this.setState({cor:'success'})  
          }
      });   

      this.state.dados.map((post)=>{
        const { crm, nome, especialidade } = post;      

        sessionStorage.setItem('@masterclinicapwa/login', 'Profissional');
        sessionStorage.setItem('@masterclinicapwa/profissional', nome);
        sessionStorage.setItem('@masterclinicapwa/crm', crm);
        sessionStorage.setItem('@masterclinicapwa/especialidade', especialidade);
        this.props.history.push('/consultas')   
       
        
    }) 
    
    e.preventDefault();
  }



  render() {
    const entrar = this.state.botao
    return (
      
      <div>
        <Topo />
        <Container className="mt-4">
        <Row>    
        <Col className="text-center titulo mt-2">Acesso exclusivo para Médicos</Col>
        </Row>
        <Row className="mt-2 bg-white justify-content-md-center">           
          <Col className="somenteDesk p-4">
          <Image src={'medicos.jpg'} className="imagem" />
          </Col>
          <Col>
       
          <Form onSubmit={this.handleSubmit} >
            <Form.Group controlId="crm">
              <Form.Label>CRM</Form.Label>
              <Form.Control name="crm" type="text" onChange={this.handleChange} />             
            </Form.Group>

            <Form.Group controlId="senha">
              <Form.Label>Senha</Form.Label>
              <Form.Control name="senha" type="password" onChange={this.handleChange} />
            </Form.Group>
            <Form.Group controlId="manter">
              <Form.Check type="checkbox" label="lembrar" />
            </Form.Group>

            <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
          </Form>
          <Row  className="mt-4 text-center">
            <Col>

          <Link to="/cadastrar"><Button variant="outline-success" size="lg" block type="submit">Não tenho senha</Button></Link>
          </Col>
          </Row>
          </Col>
          </Row>
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default Login;
