/* eslint-disable global-require */
/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Alert ,Col, Image} from 'react-bootstrap';
import axios from 'axios';
import MaskedInput from "react-maskedinput"; 
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';




class VerificaCpf extends Component {
 constructor(props) {
    super(props);
  state = {
    cpf: '',
    senha: '',
    dados: [],
    botao: 'Validar',
    cor: 'primary',
    camposenha: false
  }
}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

  }

  handleSubmit = (e) => {
    axios.get('https://mgabr.com/apiapp/demostracao/validacpf.php', {
      params: {
        cpf: this.state.cpf
      }
    }
    )
      .then(res => {
        const dados = res.data;
        this.setState({ dados });
     
        if (dados.length === 0) {
          this.setState({ botao: 'Você não tem acesso' })
          this.setState({ cor: 'danger' })
          this.setState({ camposenha:true})
        } else {
          sessionStorage.setItem('@masterclinicapwa/cpf', this.state.cpf);
          this.props.history.push('/cadastrarPaciente')
        
        }

      });

    this.state.dados.map((post) => {
      const { nome, especialidade } = post;

      sessionStorage.setItem('@masterclinicapwa/login', 'Logado');
      sessionStorage.setItem('@masterclinicapwa/profissional', nome);
      sessionStorage.setItem('@masterclinicapwa/especialidade', especialidade);
      this.props.history.push('/dashboard')


    })

    e.preventDefault();
  }



  cadastrar = (e) => {
    axios.get('https://mgabr.com/apiapp/demostracao/criarusuario.php', {
      params: {
        crm: this.state.crm,
        senha: this.state.senha
      }
    }
    )
      .then(res => {
        const dados = res.data;
        this.setState({ dados });

        if (dados.length === 0) {
          this.setState({ botao: 'Ocorreu um erro' })
          this.setState({ cor: 'danger' })
        } else {
          if(res.data === 'ALTERADO'){
            alert("Usuário Alterado com Sucesso!")
          }else if(res.data === 'CADASTRADO'){
            alert("Usuário Cadastrado com Sucesso!")
          }else{
            alert("Houve um erro!")
          }
          
          this.props.history.push('/login')
        }

      });
    e.preventDefault();
  }



  render() {
    const entrar = this.state.botao
    return (

      <div>
        <Topo />
        <Container className="mt-4">
        <Row>    
        <Col className="text-center titulo mt-2">Acesso Restrito</Col>
        </Row>
        <Row className="mt-2 bg-white justify-content-md-center">           
          <Col className="somenteDesk p-4">
          <Image src={require('./medicos.jpg')} className="imagem" />
          </Col>
          <Col>
          <Alert variant="primary mt-4">{this.state.paciente ? ('Cadastre uma senha'):("Vamos verificar se já é nosso paciente")}</Alert>
          {this.state.camposenha ? 
          (
            <Form onSubmit="#" >
              <Form.Group controlId="crm">
                <Form.Label>Digite seu CPF</Form.Label>
                <Form.Control as={MaskedInput} mask="111.111.111-11" name="crm" type="text" onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="senha" />
              <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
              <Link to="/cadastrarPaciente"><Button variant="outline-success" size="lg" block type="submit">Cadastrar ou Alterar</Button></Link>
            </Form>
            ) 
            :
            (            
            <Form onSubmit={this.handleSubmit} >
                <Form.Group controlId="crm">
                <Form.Label>Digite seu CPF</Form.Label>
                  <Form.Control  as={MaskedInput} mask="111.111.111-11"  name="cpf" type="text" onChange={this.handleChange} />
                </Form.Group>               
                <Button variant={this.state.cor} size="lg" block type="submit">{entrar}</Button>
                
              </Form>
              )}
         

              </Col>
              </Row>
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default VerificaCpf;
