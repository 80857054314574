/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/state-in-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Container, Button, Form, Row, Alert ,Col, Image} from 'react-bootstrap';
import MaskedInput from "react-maskedinput"; 
import Topo from './components/topo';
import Rodape from './components/rodape';


class LoginPaciente extends Component {

      state = {
        nome: '',
        celular: '',
        telefone: '',
        cpf: '',
        email: '',     
        nascimento: '',
      }
    
  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })    
  }

  handleSubmit =(e) => {
    sessionStorage.setItem('@masterclinicapwa/nome',this.state.nome);
    sessionStorage.setItem('@masterclinicapwa/celular',this.state.celular);
    sessionStorage.setItem('@masterclinicapwa/telefone',this.state.telefone);
    sessionStorage.setItem('@masterclinicapwa/cpf',this.state.cpf);
    sessionStorage.setItem('@masterclinicapwa/nascimento',this.state.nascimento);    
    sessionStorage.setItem('@masterclinicapwa/email', this.state.email);     
    this.props.history.push('/especialidades')   
    e.preventDefault();
  }



  render() {
    return (

      
      
      <div>
          <style type="text/css">
            {`
                .btn{margin-top:10px !important}
                .form-control{margin-bottom:0px !important;}
            `}
          </style>
        <Topo />
        <Container>
        <Row>   
        <Col className="text-center titulo mt-2">Dados Pessoais</Col>
        </Row>
        <Row>           
          <Col xs={12} md={6}>
             <Form.Group controlId="nome">
              <Form.Label>Nome Completo</Form.Label>
              <Form.Control name="nome" type="text" onChange={this.handleChange} required />             
            </Form.Group>
             <Form.Group controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control  as={MaskedInput} mask="(11)11111-1111" name="celular" type="text" onChange={this.handleChange} required />             
            </Form.Group>
             <Form.Group controlId="telefone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control as={MaskedInput} mask="(11)1111-1111" name="telefone" type="text" onChange={this.handleChange} />             
            </Form.Group>         
          </Col>

         <Col xs={12} md={6}>
          <Form onSubmit={this.handleSubmit} >
             <Form.Group controlId="cpf">
              <Form.Label>CPF</Form.Label>
              <Form.Control as={MaskedInput} mask="111.111.111-11"  name="cpf" type="text" onChange={this.handleChange} required />             
            </Form.Group>
             <Form.Group controlId="nascimento">
              <Form.Label>Data de Nascimento</Form.Label>
              <Form.Control as={MaskedInput} mask="11/11/1111" name="nascimento" type="text" onChange={this.handleChange} />             
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" type="email" onChange={this.handleChange} required />             
            </Form.Group>
            <Button size="lg" block type="submit">Continuar</Button>
          </Form>
          </Col>
          </Row>
        </Container>
        <Rodape />
      </div>

    )
  };
};

export default LoginPaciente;
