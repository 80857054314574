import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Calendario from './Calendario';
import Laudos from './Laudos';
import * as serviceWorker from './serviceWorker';
import Especialidades from './Especialidade';
import Profissionais from './Profissionais';
import ConfirmaAgendamento from './ConfirmaAgendamento';
import Convenio from './Convenio';
import Sucesso from './sucesso';
import Login from './Login';
import LoginPaciente from './loginPaciente';
import Consultas from './consultas';
import prontuariosPaciente from './prontuariosPaciente';
import mostraProntuario from './mostraProntuario';
import mostraProntuarioExterno from './mostraProntuarioExterno';
import mostraAgendasProfissional from './mostraAgendasProfissional';
import Dashboard  from './dashboard';
import laudosProfissional from './laudosProfissional';
import Cadastrar from './cadastrar';
import inicioPaciente from './inicioPaciente';
import CadastrarPaciente from './cadastrarPaciente';
import RecuperarSenha from './recuperarsenha';
import NovaSenha from './novasenha';
import VerificaCpf from './verificaCpf';

ReactDOM.render(
    <BrowserRouter>
        <Switch>

            <Route path="/" exact component={inicioPaciente} />
            <Route path="/inicioPaciente" exact component={inicioPaciente} />
            <Route path="/calendario" component ={Calendario} />
            <Route path="/laudos" component ={Laudos} />
            <Route path="/especialidades" component ={Especialidades} />
            <Route path="/profissionais" component ={Profissionais} />
            <Route path="/confirma_agendamento" component ={ConfirmaAgendamento} />
            <Route path="/convenio" component ={Convenio} />
            <Route path="/sucesso" component ={Sucesso} />
            <Route path="/login" component ={Login} />
            <Route path="/loginPaciente" component ={LoginPaciente} />
            <Route path="/consultas" component ={Consultas} />
            <Route path="/prontuariosPaciente" component ={prontuariosPaciente} />
            <Route path="/mostraProntuario" component ={mostraProntuario} />
            <Route path="/mostraAgendasProfissional" component ={mostraAgendasProfissional} />
            <Route path="/dashboard" component ={Dashboard} />
            <Route path="/laudosProfissional" component ={laudosProfissional} />
            <Route path="/cadastrar" component ={Cadastrar} />
            <Route path="/cadastrarPaciente" component ={CadastrarPaciente} />
            <Route path="/recuperarSenha" component ={RecuperarSenha} />
            <Route path="/mostraProntuarioExterno" component ={mostraProntuarioExterno} />
            <Route path="/novaSenha" component ={NovaSenha} />
            <Route path="/verificaCpf" component ={VerificaCpf} />
     
        </Switch>
    </BrowserRouter>,
    document.getElementById('root'));

serviceWorker.register();
